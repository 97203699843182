<template>
    <div class="not-found">
      <h1>404</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <router-link to="/" class="home-link">Go Back to Home</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: "NotFound",
  };
  </script>
  
  <style scoped>
  .not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f8f8;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .not-found h1 {
    font-size: 6rem;
    margin: 0;
    font-weight: bold;
    color: #ff6b6b;
  }
  
  .not-found p {
    font-size: 1.5rem;
    margin: 10px 0 20px;
  }
  
  .home-link {
    padding: 10px 20px;
    border: 2px solid #ff6b6b;
    color: #ff6b6b;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-top: 30px;
  }
  
  .home-link:hover {
    background-color: #ff6b6b;
    color: #fff;
  }
  </style>
  